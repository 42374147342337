import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RrsCartEntryConnector } from '@app/custom/features/rrs-cart/occ/rrs-cart-entry.connector';
import { RrsMultiCartService } from '@app/custom/features/rrs-cart/services/rrs-multi-cart.service';
import {
  RrsCartAddEntryEvent,
  RrsCartAddEntryFailEvent,
  RrsCartAddEntrySuccessEvent,
} from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { Store } from '@ngrx/store';
import {
  ActiveCartService,
  CartActions,
  getCartIdByUserId,
} from '@spartacus/cart/base/core';
import {
  Cart,
  CartAddEntryFailEvent,
  CartModification,
  OrderEntry,
} from '@spartacus/cart/base/root';
import {
  AuthService,
  CmsService,
  EventService,
  normalizeHttpError,
  OCC_USER_ID_ANONYMOUS,
  UserIdService,
} from '@spartacus/core';
import { BehaviorSubject, combineLatest, forkJoin, from } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsActiveCartService extends ActiveCartService {
  isCartProcessing$ = new BehaviorSubject(false);

  constructor(
    protected multiCartService: RrsMultiCartService,
    userIdService: UserIdService,
    protected cartEntryConnector: RrsCartEntryConnector,
    protected store: Store,
    protected authService: AuthService,
    protected eventService: EventService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cmsService: CmsService
  ) {
    super(multiCartService, userIdService);
    this.loadCartFromUrl();
  }

  addEntries(cartEntries: OrderEntry[]): void {
    combineLatest([this.requireLoadedCart(), this.userIdService.takeUserId()])
      .pipe(
        take(1),
        switchMap(([cart, userId]) => {
          this.isCartProcessing$.next(true);
          const cartId = getCartIdByUserId(cart, userId);
          return forkJoin(
            cartEntries.map((entry) => {
              const payload = {
                userId,
                cartId,
                productCode: entry.product?.code ?? '',
                quantity: entry.quantity ?? 0,
              };
              this.eventService.dispatch(
                this.cartEventFactory(
                  new RrsCartAddEntryEvent(),
                  entry.product?.baseProduct ?? '',
                  payload.quantity,
                  cartId,
                  entry.product?.price?.value ?? 0,
                  cart
                ),
                RrsCartAddEntryEvent
              );
              return this.cartEntryConnector
                .addOrderEntry(userId, cartId, entry)
                .pipe(
                  mergeMap((cartModification: CartModification) => {
                    this.activeCart$.pipe(take(1)).subscribe((cart) => {
                      this.eventService.dispatch(
                        this.cartEventFactory(
                          new RrsCartAddEntrySuccessEvent(),
                          entry.product?.baseProduct ?? '',
                          payload.quantity,
                          cartId,
                          entry.product?.price?.value ?? 0,
                          cart
                        ),
                        RrsCartAddEntrySuccessEvent
                      );
                    });
                    return [
                      new CartActions.CartAddEntrySuccess({
                        ...payload,
                        ...(cartModification as Required<CartModification>),
                      }),
                      new CartActions.LoadCart({
                        cartId: payload.cartId,
                        userId: payload.userId,
                      }),
                    ];
                  }),
                  catchError((error) => {
                    this.eventService.dispatch(
                      this.cartEventFactory(
                        new RrsCartAddEntryFailEvent(),
                        entry.product?.baseProduct ?? '',
                        payload.quantity,
                        cartId,
                        entry.product?.price?.value ?? 0,
                        cart,
                        error
                      ),
                      RrsCartAddEntryFailEvent
                    );
                    return from([
                      new CartActions.CartAddEntryFail({
                        ...payload,
                        error: normalizeHttpError(error),
                      }),
                      new CartActions.LoadCart({
                        cartId: payload.cartId,
                        userId: payload.userId,
                      }),
                    ]);
                  })
                );
            })
          );
        })
      )
      .subscribe((actions) => {
        this.isCartProcessing$.next(false);
        if (actions) actions.forEach((action) => this.store.dispatch(action));
      });
  }

  cartEventFactory(
    event:
      | RrsCartAddEntryEvent
      | RrsCartAddEntrySuccessEvent
      | RrsCartAddEntryFailEvent,
    productCode: string,
    quantity: number,
    cartId: string,
    price: number,
    cart: Cart,
    error?: any
  ):
    | RrsCartAddEntryEvent
    | RrsCartAddEntrySuccessEvent
    | RrsCartAddEntryFailEvent {
    const newEvent = { ...event };
    newEvent.productCode = productCode;
    newEvent.quantity = quantity;
    newEvent.cartId = cartId;
    newEvent.price = price;
    newEvent.cart = cart;

    if (error) {
      (newEvent as CartAddEntryFailEvent).error = error;
    }
    return newEvent;
  }

  loadCartFromUrl(): void {
    combineLatest([
      this.cmsService.getCurrentPage(),
      this.getActiveCartId(),
      this.activatedRoute.queryParams,
      this.router.events.pipe(
        map((event) => {
          return event;
        }),
        map((event) => event instanceof NavigationEnd),
        filter((isNavigationEndEvent) => isNavigationEndEvent)
      ),
    ]).subscribe(
      ([currentPage, activeCartId, queryParams, isNavigationEnd]) => {
        if (
          isNavigationEnd &&
          (currentPage?.template === 'CartPageTemplate' ||
            currentPage?.template === 'CartEmptyPageTemplate') &&
          queryParams?.cartId?.length &&
          activeCartId !== queryParams.cartId
        ) {
          const loadCartObject = {
            cartId: queryParams.cartId,
            userId: queryParams.userId || OCC_USER_ID_ANONYMOUS,
          };
          this.store.dispatch(
            new CartActions.SetActiveCartId(loadCartObject.cartId)
          );
          this.multiCartFacade.loadCart(loadCartObject);
        }
      }
    );
  }
}
