import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ProductListItemComponent,
  ProductListItemContext,
  ProductListItemContextSource,
} from '@spartacus/storefront';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { Badge } from '@app/shared/configs';
import { getBadges, includeBogoBadgesIfApplicable } from '@app/shared/utils';
import { Product } from '@spartacus/core';
import { RrsPDPFacets } from '@app/custom/features/rrs-product-listing/model';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';
import { Subscription } from 'rxjs';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { tap } from 'rxjs/operators';
import { RrsShopLookService } from '@app/custom/features/rrs-shop-look/services/rrs-shop-look.service';

@AutoUnsubscribe()
@Component({
  selector: 'rrs-product-card',
  templateUrl: './rrs-product-card.component.html',
  styleUrls: ['./rrs-product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class RrsProductCardComponent
  extends ProductListItemComponent
  implements OnInit
{
  @Input() product!: Product;
  @Input() queryParams!: RrsPDPFacets;
  @Input() isShopLook: boolean = false;

  badges: Array<Badge> = [];
  bogoBadges: Array<Badge> = [];
  isMobile: boolean = false;
  private subscriptions = new Subscription();
  constructor(
    productListItemContextSource: ProductListItemContextSource,
    protected eventsDispatcher: RrsEventsDispatcherService,
    protected breakpointService: BreakpointService,
    protected rrsShopLookService: RrsShopLookService
  ) {
    super(productListItemContextSource);
  }

  ngOnInit(): void {
    if (this.product?.categories) {
      this.badges = getBadges(this.product.categories);
      this.bogoBadges = includeBogoBadgesIfApplicable(this.product.categories);
    }

    this.subscriptions.add(
      this.breakpointService
        .isDown(BREAKPOINT.md)
        .pipe(
          tap((bool) => {
            this.isMobile = bool;
          })
        )
        .subscribe()
    );
  }

  dispatchEvent(product: Product): void {
    this.eventsDispatcher.dispatchInternalSearchClick(product.code ?? '');
  }
  openPdp(code: string): void {
    this.rrsShopLookService.openPDP(code);
  }
}
