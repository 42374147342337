<div class="position-relative">
  <rrs-media
    [container]="product.images?.PRIMARY"
    [alt]="product.name"
    format="product"
    [height]="isShopLook ? 151 : 263"
    [width]="isShopLook ? 160 : 322"
    data-cy="productCardImage">
  </rrs-media>
  <div class="mx-2">
    <h2 class="fs-5 fw-semibold mb-0" data-cy="productCardBrand">
      {{ product.manufacturer }}
    </h2>
    <h3 class="fs-6 text-truncate fw-normal mb-2" data-cy="productCardName">
      {{ product.name }}
    </h3>
    <div class="product-card__badges d-flex flex-wrap align-content-end">
      <a class="d-flex" *ngFor="let badge of badges">
        <span
          [style.background-color]="badge.bgColor"
          [style.color]="badge.textColor"
          [style.border]="badge.border"
          class="badge"
          >{{ badge.label }}</span
        >
      </a>
    </div>
    <div
      *ngIf="product.price?.isVisible; else priceHidden"
      class="d-flex flex-column justify-content-start flex-wrap lh-10px"
      [ngClass]="badges.length >= 1 ? 'mt-1' : 'mt-0'">
      <span
        *ngIf="!product.oldPrice; else discounted"
        data-cy="productCardPrice">
        {{ product.price?.value | currency: 'USD' }}
      </span>
    </div>
    <ng-template #priceHidden>
      <p>
        {{ 'rrs.product.SeePriceInCart' | cxTranslate }}
      </p>
    </ng-template>
    <div class="product-card__rating mb-2">
      <rrs-star-rating
        *ngIf="!!product.averageRating"
        size="0.81rem"
        data-cy="productCardRating"
        [rating]="product.averageRating || 0"></rrs-star-rating>
    </div>
    <div class="product-card__badges d-flex flex-wrap align-content-end">
      <a
        class="d-flex z-1000"
        *ngFor="let badge of bogoBadges"
        [href]="badge.categoryUrl"
        target="_blank"
        [innerHTML]="badge.label"
        [class]="badge.class">
      </a>
    </div>
    <a
      *ngIf="!isShopLook; else buttonForShopLook"
      (click)="dispatchEvent(product)"
      [routerLink]="[product.url]"
      [queryParams]="queryParams"
      [attr.aria-label]="product.name"
      class="stretched-link"></a>
    <ng-template #buttonForShopLook>
      <button
        *ngIf="isShopLook"
        (click)="dispatchEvent(product); openPdp(product.code)"
        [attr.aria-label]="product.name"
        class="stretched-link"></button>
    </ng-template>
  </div>
</div>

<ng-template #discounted>
  <div class="text-success fw-semibold mb-2" data-cy="productCardPrice">
    {{ 'rrs.productCard.sale' | cxTranslate }}&nbsp;{{
      product.price?.value | currency: 'USD'
    }}
  </div>
  <div class="text-light text-nowrap">
    {{ 'rrs.productCard.was' | cxTranslate }}&nbsp;<span
      class="text-decoration-line-through">
      {{ product.oldPrice?.value | currency: 'USD' }}</span
    >
  </div>
</ng-template>
