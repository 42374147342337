import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { RrsShopLookService } from '@app/custom/features/rrs-shop-look/services/rrs-shop-look.service';

@Component({
  selector: 'rrs-shop-look-icon',
  template: `<span
    class="rrs-shop-look-icon"
    (click)="openPlp()"
    (keydown)="(openPlp)"></span>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsShopLookIconComponent {
  codes: string[] = [];
  shoplooktitle: string = '';
  @Input() set products(codes: string) {
    this.rrsShopLookService.codes = [];
    if (codes.length) {
      this.codes = codes.split(',');
    }
  }
  @Input() set title(title: string) {
    this.rrsShopLookService.title = '';
    if (title.length) {
      this.shoplooktitle = title;
    }
  }
  constructor(protected rrsShopLookService: RrsShopLookService) {}
  openPlp(): void {
    this.rrsShopLookService.codes = this.codes;
    this.rrsShopLookService.title = this.shoplooktitle;
    if (this.rrsShopLookService.codes.length === 1) {
      this.rrsShopLookService.openPDP(this.rrsShopLookService.codes[0]);
    }
    if (this.rrsShopLookService.codes.length > 1) {
      this.rrsShopLookService.openPLP();
    }
  }
}
